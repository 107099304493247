import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Formik, Form as FormikForm } from 'formik'
import { get, map, sortBy, indexOf } from 'lodash'

import { nodes, postNetlifyForm } from 'src/helpers'
import {
  Container,
  FieldRow,
  StyledField,
  SubmitButton,
  ThankYouContainer,
} from './Form.styled'

const NETLIFY_FORM_NAME = 'Contact'

const onSubmit = async (values, { setSubmitting, setStatus }) => {
  const result = await postNetlifyForm(NETLIFY_FORM_NAME, {
    ...values,
    subject: `[${values.location}] ${NETLIFY_FORM_NAME} form submission`,
  })
  if (result) setStatus({ submitted: true })
  setSubmitting(false)
}

export const render = props => queryData => {
  const locationsPreferredOrder = ['Kailua Beach', 'Kailua Town', 'Kapolei']
  const locations = sortBy(nodes(get(queryData, 'allPrismicLocation')), x =>
    indexOf(locationsPreferredOrder, get(x, 'data.location_name'))
  )

  return (
    <Container {...props}>
      <Formik
        initialValues={{
          subject: `${NETLIFY_FORM_NAME} form submission`,
          fullName: '',
          emailAddress: '',
          location: '',
          message: '',
        }}
        onSubmit={onSubmit}
      >
        {({ values, status, isSubmitting }) =>
          get(status, 'submitted') ? (
            <ThankYouContainer
              dangerouslySetInnerHTML={{
                __html: get(
                  queryData,
                  'prismicContactPage.data.form_success_text.html'
                ),
              }}
            />
          ) : (
            <FormikForm
              name={NETLIFY_FORM_NAME}
              data-netlify={true}
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="subject" />
              <FieldRow>
                <StyledField name="fullName" placeholder="Name" required />
              </FieldRow>
              <FieldRow>
                <StyledField
                  type="email"
                  name="emailAddress"
                  placeholder="Email Address"
                  required
                />
              </FieldRow>
              <FieldRow>
                <StyledField
                  name="location"
                  component="select"
                  required
                  defaultValue=""
                >
                  <option value="" disabled required>
                    Location this is concerning
                  </option>
                  {map(locations, location => (
                    <option
                      key={get(location, 'data.location_name')}
                      value={get(location, 'data.location_name')}
                    >
                      {get(location, 'data.location_name')}
                    </option>
                  ))}
                </StyledField>
              </FieldRow>
              <FieldRow>
                <StyledField
                  component="textarea"
                  name="message"
                  placeholder="Message"
                  required
                />
              </FieldRow>
              <SubmitButton
                type="submit"
                value={isSubmitting ? 'Submitting…' : 'Submit'}
                disabled={isSubmitting}
              />
            </FormikForm>
          )
        }
      </Formik>
    </Container>
  )
}

export const Form = props => (
  <StaticQuery
    query={graphql`
      query {
        prismicContactPage {
          data {
            form_success_text {
              html
            }
          }
        }
        allPrismicLocation {
          edges {
            node {
              uid
              data {
                location_name
              }
            }
          }
        }
      }
    `}
    render={render(props)}
  />
)
