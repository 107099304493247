import styled from 'react-emotion'

import t from 'src/theme'
import { PinkBox } from 'src/components/Shared/PinkBox/PinkBox'
import { OffsetImages } from 'src/components/Shared/OffsetImages/OffsetImages'
import AssetTextureLifeguardStand from 'src/assets/texture-lifeguard-stand.png'

export const StyledPinkBox = styled(PinkBox)`
  ${t.mq.s} {
    background-image: url(${AssetTextureLifeguardStand});
    background-repeat: no-repeat;
    background-position: 75% -200%;
    background-size: auto 105%;
  }
`

export const CenteredHeading = styled.h1`
  text-align: center;
`

export const Hours = styled.div`
  p {
    margin-bottom: 0;
  }
`
export const Address = styled.div`
  p {
    margin-bottom: 0;
  }
`

export const StyledOffsetImages = styled(OffsetImages)`
  margin-left: -${t.s(2)};
  margin-right: -${t.s(2)};
`
