import styled from 'react-emotion'
import InfoBox from "react-google-maps/lib/components/addons/InfoBox"
import t from 'src/theme'

export const Container = styled.div`
  height: 50vh;

  .infoBox{
    overflow: visible !important;
  }
`

export const StyledInfoBox = styled(InfoBox)`
`
export const StyledInfoBoxInner = styled.div`
  background-color: white;
  padding: ${t.s(-1)};
  width: 200px;
  text-align: center;
  color: ${t.c.green};
  font-size: ${t.f(0)};
  font-family: ${t.ff.monoBody};
  transform: translateX(-100px);
  h1 {
    padding: 0;
    margin: 0;
    font-size: ${t.f(0)};
  }
  p, a{
    color: ${t.c.green};
    font-size: ${t.f(-1)};
    margin-bottom: 0;
    text-align: center;
  }
`