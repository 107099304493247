import styled from 'react-emotion'
import t from 'src/theme'

export const FlexWrapChild = styled.div`
  padding-top: ${t.s(2)};
  padding-left: ${t.s(2)};
`

export const Container = styled.div`
  border: 1px solid ${t.c.gray};
  padding: 4px;
  border-radius: 4px;
  height: 100%;
`

export const Content = styled.div`
  border: 1px solid ${t.c.gray};
  padding: ${t.s(2)};
  height: 100%;
  border-radius: 2px;
  text-align: center;

  h3 {
    color: ${t.c.lightGreen};
    font-family: ${t.ff.sansHeadline};
    font-size: ${t.f(2)};
    font-weight: bold;
    margin: 0 0 ${t.s(-4)};
    text-align: center;
    text-transform: uppercase;
  }

  h4 {
    color: ${t.c.gray};
    font-family: ${t.ff.monoBody};
    font-size: ${t.f(-0.5)};
    font-weight: 700;
    letter-spacing: 0.1em;
    margin-bottom: ${t.s(-4)};
    position: relative;
    text-transform: uppercase;
  }

  p {
    margin-bottom: ${t.s(0)};
    max-width: 13rem;
    margin-left: auto;
    margin-right: auto;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`
