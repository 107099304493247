import styled from 'react-emotion'
import { Field } from 'formik'

import t from 'src/theme'
import AssetIconChevronDownSVG from 'src/assets/icon-chevron-down.svg'

export const Container = styled.div`
  position: relative;
`

export const FieldRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${t.s(0)};
`

export const StyledField = styled(Field)`
  appearance: none;
  background-image: ${p =>
    p.component === 'select' ? `url(${AssetIconChevronDownSVG})` : 'none'};
  background-color: ${t.c.white};
  background-position: right ${t.s(0)} center;
  background-repeat: no-repeat;
  background-size: ${t.s(0)} auto;
  border-radius: ${t.r(-8)};
  border: 1px solid ${t.c.gray};
  color: ${t.c.darkGray};
  flex-basis: 50%;
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(-0.5)};
  height: ${p => (p.component === 'textarea' ? t.s(7) : 'auto')};
  margin-bottom: ${t.s(0)};
  padding: ${t.s(-1, 0)};
  text-align: ${p => (p.component === 'textarea' ? 'left' : 'center')};

  ${t.mq.m} {
    margin-bottom: 0;
    margin-right: ${t.s(1)};
    text-align: left;
  }

  &:first-child:last-child {
    flex-basis: 100%;
  }

  &:last-child {
    margin-bottom: 0;
    margin-right: 0;
  }

  &::placeholder,
  &:invalid {
    color: ${t.c.gray};
    font-style: italic;
  }
`

export const SubmitButton = styled.input`
  appearance: none;
  background-color: ${p => (p.disabled ? t.c.gray : t.c.lightGreen)};
  border-radius: 3px;
  border: 0;
  color: ${t.c.white};
  cursor: pointer;
  display: block;
  font-family: ${t.ff.monoBody};
  font-size: ${t.f(0)};
  font-weight: bold;
  letter-spacing: 0.1em;
  margin: ${t.s(1)} auto 0;
  padding: ${t.s(-1)} ${t.s(4)};
  text-align: center;
  text-transform: uppercase;
  transition-duration: ${t.t};
  transition-property: background-color, color;

  &:hover {
    background-color: ${t.c.green};
    color: ${t.c.white};
  }
`

export const ThankYouContainer = styled.div`
  text-align: center;
`
