import React from 'react'
import Component from '@reach/component-component'
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap as ReactGoogleMap,
  Marker,
} from 'react-google-maps'
import { get } from 'lodash'

import { windowExists } from 'src/helpers'
import { Link } from 'src/components/Shared/Link/Link'
import { Container, StyledInfoBox, StyledInfoBoxInner } from './Map.styled'

const initialState = {
  activeLocation: null,
  activeHoveredLocation: null,
  mapRef: null,
  lat: 21.408805610807473,
  lng: -157.9242911466851,
  zoom: 10,
}

let mapRef = null

const GoogleMapImpl = ({ locations = [], ...props }) => {
  if (windowExists === false) {
    return
  }
  return (
    <Component initialState={initialState}>
      {({ state, setState }) => (
        <ReactGoogleMap
          ref={comp => (mapRef = comp)}
          center={{ lat: state.lat, lng: state.lng }}
          defaultCenter={{ lat: initialState.lat, lng: initialState.lng }}
          defaultZoom={initialState.zoom}
          onCenterChanged={() =>
            setState({
              lat: mapRef.getCenter().lat(),
              lng: mapRef.getCenter().lng(),
            })
          }
          onZoomChanged={() => setState({ zoom: mapRef.getZoom() })}
          zoom={state.zoom}
          {...props}
        >
          {locations.map(location => (
            <React.Fragment key={location.uid}>
              <Marker
                position={{
                  lat: get(location, 'data.geopoint.latitude'),
                  lng: get(location, 'data.geopoint.longitude'),
                }}
                onClick={() =>
                  setState({
                    activeLocation: location.uid,
                    zoom: 15,
                    lat: get(location, 'data.geopoint.latitude'),
                    lng: get(location, 'data.geopoint.longitude'),
                  })
                }
                onMouseOver={() => {
                  clearTimeout(state.closeInfoBoxTimeout)
                  setState({ activeHoveredLocation: location.uid })
                }}
                onMouseOut={() => {
                  setState({
                    closeInfoBoxTimeout: setTimeout(() => {
                      setState({ activeHoveredLocation: null })
                    }, 800),
                  })
                }}
              />
              {state.activeHoveredLocation === location.uid && (
                <StyledInfoBox
                  defaultPosition={
                    new window.google.maps.LatLng(
                      get(location, 'data.geopoint.latitude'),
                      get(location, 'data.geopoint.longitude')
                    )
                  }
                  options={{ closeBoxURL: ``, enableEventPropagation: true }}
                >
                  <StyledInfoBoxInner
                    onMouseLeave={() =>
                      setTimeout(() => {
                        setState({ activeHoveredLocation: null })
                      }, 0)
                    }
                    onMouseEnter={() => {
                      clearTimeout(state.closeInfoBoxTimeout)
                    }}
                  >
                    <h1>{get(location, 'data.location_name')}</h1>
                    <Link to={get(location, 'data.google_maps_link.url')}>
                      <p
                        dangerouslySetInnerHTML={{
                          __html: get(location, 'data.location_address1.html'),
                        }}
                      />
                    </Link>
                  </StyledInfoBoxInner>
                </StyledInfoBox>
              )}
            </React.Fragment>
          ))}
        </ReactGoogleMap>
      )}
    </Component>
  )
}

// withScriptjs, withGoolgeMap are required to render the map.
const WrappedGoogleMapImpl = withScriptjs(withGoogleMap(GoogleMapImpl))

// Queries for all locations and provides the data to the map implementation.
export const Map = props => (
  <WrappedGoogleMapImpl
    containerElement={<Container />}
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${
      process.env.GATSBY_GOOGLE_API_KEY
    }&libraries=geometry`}
    loadingElement={<div style={{ height: '100%' }} />}
    mapElement={<div style={{ height: '100%' }} />}
    {...props}
  />
)
