import styled from 'react-emotion'
import t from 'src/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -${t.s(2)};
  margin-top: -${t.s(2)};

  ${t.mq.m} {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
`
