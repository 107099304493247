import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { get, map, sortBy, indexOf } from 'lodash'

import { nodes } from 'src/helpers'
import { CreamBox } from 'src/components/Shared/CreamBox/CreamBox'
import { Form } from 'src/components/Contact/Form/Form'
import { Frame } from 'src/components/Contact/Frame/Frame'
import { Frames } from 'src/components/Contact/Frames/Frames'
import { Layout } from 'src/components/Layout/Layout'
import { Link } from 'src/components/Shared/Link/Link'
import { Map } from 'src/components/Contact/Map/Map'
import { SmallContentContainer } from 'src/components/Layout/Structure'
import {
  StyledPinkBox,
  CenteredHeading,
  Address,
  Hours,
  StyledOffsetImages,
} from './_contact.styled'

export const ContactPage = ({ data }) => {
  const page = get(data, 'prismicContactPage')
  const locationsPreferredOrder = [
    'Kailua Beach',
    'Kailua Town',
    'Kapolei',
    'Waimanalo',
  ]
  const locations = sortBy(nodes(get(data, 'allPrismicLocation')), x =>
    indexOf(locationsPreferredOrder, get(x, 'data.location_name'))
  )

  return (
    <Layout activePageName="contact">
      <Helmet title={get(page, 'data.title.text')} />
      <StyledPinkBox>
        <div
          dangerouslySetInnerHTML={{ __html: get(page, 'data.intro.html') }}
        />
      </StyledPinkBox>
      <CreamBox variant="wide">
        <Frames>
          {map(locations, item => (
            <Frame key={get(item, 'data.location_name')}>
              <h3>{get(item, 'data.location_name')}</h3>
              <h4>Hours</h4>
              <Hours
                dangerouslySetInnerHTML={{
                  __html: get(item, 'data.hours.html'),
                }}
              />
              <h4>Address</h4>
              <Link to={get(item, 'data.google_maps_link.url')}>
                <Address
                  dangerouslySetInnerHTML={{
                    __html: get(item, 'data.location_address1.html'),
                  }}
                />
              </Link>
              <h4>Contact</h4>
              <p>
                <Link to={`tel:${get(item, 'data.phone_number')}`}>
                  {get(item, 'data.phone_number')}
                </Link>
                <br />
                <Link to={`mailto:${get(item, 'data.email')}`}>
                  {get(item, 'data.email')}
                </Link>
              </p>
            </Frame>
          ))}
        </Frames>
      </CreamBox>
      <Map locations={locations} />
      <CreamBox contain={false}>
        <StyledOffsetImages
          imageOneUrl={get(page, 'data.image_one.url')}
          imageTwoUrl={get(page, 'data.image_two.url')}
          message="You're always with friends <br /> at Kalapawai."
        />
        <SmallContentContainer>
          <CenteredHeading>General Inquiries</CenteredHeading>

          <Form />
        </SmallContentContainer>
      </CreamBox>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query {
    prismicContactPage {
      data {
        title {
          text
        }
        intro {
          html
        }
        image_one {
          url
        }
        image_two {
          url
        }
      }
    }
    allPrismicLocation {
      edges {
        node {
          uid
          data {
            location_name
            geopoint {
              latitude
              longitude
            }
            hours {
              text
              html
            }
            google_maps_link {
              url
            }
            location_address1 {
              html
              text
            }
            phone_number
            email
          }
        }
      }
    }
  }
`
