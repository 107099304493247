import React from 'react'

import { Container, Content, FlexWrapChild } from './Frame.styled'

export const Frame = ({ children, ...props }) => (
  <FlexWrapChild>
    <Container {...props}>
      <Content>{children}</Content>
    </Container>
  </FlexWrapChild>
)
